//common
@import '~antd/dist/antd.css';

.adf{
    width: 52px;
    height: 52px;
    background: #ffffff;
    border: 1px solid rgba(0,0,0,0.10);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 40px;
    right: 10px;
    cursor: pointer;
    z-index: 9;
}


.home-h3-title{
    text-align: center;
    font-weight: 700;
    padding-bottom: 10px;
    font-size: 36px;
}
.bg-gray{
    background: #f8f9fa;
}
.news-dots {
    li {
       button {
        box-shadow: 0 0 4px #bbb;
        background-color: #aaa !important;
       } 
    //    #0048FF
     &.slick-active {
        button {
            background-color: #0048FF !important;
            box-shadow: none
        }
     }
    }

}
.product-item{
    width: 100%;
    // background-color: blue;
    border-radius: 8px;
    color: #fff;
    padding: 20px 40px 0;
    background-size: contain;
    &.product-item-bg-1 {
        height: 520px;
        background: #0048FF url(https://statres.wk1762.com/imgs/product-item_1.png) no-repeat top right;
    }
    &.product-item-bg-2 {
        height: 250px;
        background: #2AC082 url(https://statres.wk1762.com/imgs/product-item_2.png) no-repeat top right;
    }
    &.product-item-bg-3 {
        height: 250px;
        background: #006FFF url(https://statres.wk1762.com/imgs/product-item_3.png) no-repeat top right;

    }
    &.product-item-bg-4 {
        height: 250px;
        background: #009FC2 url(https://statres.wk1762.com/imgs/product-item_4.png) no-repeat top right;

    }
    &.product-item-bg-5 {
        height: 250px;
        background: #536377 url(https://statres.wk1762.com/imgs/product-item_5.png) no-repeat top right;

    }
    h5{
        color: #fff;
        font-weight: 700;
        font-size: 28px;
    }
    p{
        font-size: 16px;
        font-weight: 400;
    }
    .product-item-number{
        .value{
            font-size: 60px;
            font-weight: 700;
            line-height: 80px;
        }
        .subscript{
            font-size: 16px;
            padding-left: 6px;
            font-weight: 400;
        }
    }
}

.rela{
    position: relative;
}
.back{
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    cursor: pointer;
}
.ant-popover-inner-content{
    padding-left: 0;
    padding-right: 0;
}
.menu_sub{
    p{
        margin-bottom: 0;
        padding: 5px 10px;
        cursor: pointer;
        color: #aaa;
        &:hover{
            color: #fff;
        }
        &.active{
            color: #fff;
        }
    }
}


// header
.header-wrapper{
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    .header-content{
        display: flex;
        align-items: center;
        justify-content: space-around;
        
        .menu_wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 1 0 auto;
            .wk_logo{
                flex: 0 1 280px;
                width: 280px;
                height: 80px;
                margin-right: 80px;
                background-image: url(../../public/imgs/logo.png);
                background-repeat: no-repeat;
                background-position: center left;
                background-size: 100%;
                cursor: pointer;
            }
            .wk_menu{
                flex: 1 0 auto;
                
                .wk_menu_item{
                    margin: 0 16px;
                    color: #B2B2B2;
                    &.active {
                        font-weight: 700;
                        color: #fff;
                    }
                }
            }
        }
        .wk_translate{
            flex: 0 0 100px;
            width: 100px;
            text-align: right;
            .wk_translate_item{
                margin: 0 4px;
                color: #B2B2B2;
                cursor: pointer;
                &.active {
                    font-weight: 700;
                    color: #fff;
                }
            }
        }
    }
}

// footer
.wk_footer{
    background-color: #212121;
    color: #B2B2B2;
    padding: 80px 0;
    h6{
        font-size: 16px;
        color: #ffffff;
        padding-bottom: 8px;
    }
    .wk_footer-connent{
        border-bottom: 1px solid rgba(255,255,255,0.10);
        margin-bottom: 20px;
        padding-bottom: 10px;
        .wk_footer-connent-menu{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            span{
                font-size: 16px;
                cursor: pointer;
            }
            .connent-menu-item{
                padding-right: 20px;
                flex: 1;
                ul{
                    margin: 0;
                    padding: 0;
                    li {
                        list-style: none;
                        padding-bottom: 8px;
                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .wk_footer-connent-qr{
            width: 160px;
            text-align: center;
            float: right;
            p{
                padding-top: 8px;
                font-size: 14px;
            }
        }
    }
    .wk_footer-contact{  
        .wk_footer-contact-list{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: 30px;
            .contact-list-item{
                flex: 0 1 30%;
                .contact-list-item-flex{
                    display: flex;
                    align-items: top;
                    padding-bottom: 10px;
                    
                    span {
                        padding-right: 8px;
                        &.telephone{
                            padding-right: 9px;
                            padding-left: 2px;
                        }
                    }
                    p{
                        font-size: 16px;
                    }
                }
                p{
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    margin: 0;
                }
            }
        }
    }
    .wk_footer-copyright{
        font-size: 14px;
        text-align: center;
    }
}

// home

//banner
.homepage-video-wrap {
    height: 100vh;
    min-height: 410px;
    overflow: hidden;
    text-align: center;
    position: relative;
    background-image: url(https://statres.wk1762.com/imgs/video-poster.png);
    background-size: 100% 100%;
    #wk-video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    } 
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;
        .overlay-wrapper{
            width: 100%;
            background: rgba(0, 0, 0, 0.02);
            backdrop-filter: blur(50px); /* 添加模糊效果 */
            -webkit-backdrop-filter: blur(50px); 
        }
        .overlay-content{
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 20px 0;
            .overlay-item {
                width: 33.33%;
                flex: 0 1 33.33%;
                padding: 0 16px;
                &:nth-of-type(2) {
                    border-left: 1px solid #fff;
                    border-right: 1px solid #fff;
                }
                h4{
                    color: #fff;
                }
                p{
                    color: #fff;
                }
            }
        }
        
    }
}

//product 产品
.homepage-product{
    padding: 20px 0 50px 0;
}
.homepage-product-header{
    padding: 50px 0;
    p{
        text-align: center;
        color: rgb(95, 95, 95);
    }
}


.homepage-product-data{
    padding: 140px 0;
    margin-top: 60px;
    background-size: contain;
    background: #010411 url(https://statres.wk1762.com/imgs/homepage-product-data-bg.png) no-repeat center right;
    .home-h4-title{
        font-size: 36px;
        font-weight: 700;
        color: #fff;
        text-align: left;
        padding-bottom: 30px;
    }
    .product-item{
        padding: 0px;
    }
}


// media 媒体
// 竖向间隔 0 160 320     横向 320 120 160
.homepage-media{
    background: #E8EDF7 url(https://statres.wk1762.com/imgs/homepage-media-bg.png) no-repeat top center;
    background-size: cover;
    min-height: 700px;
    .media-item{
        position: absolute;
        &:nth-of-type(1) {
            top: 0;
            left: 300px;
        }
        &:nth-of-type(2) {
            top: 0;
            right: 300px;
        }
        &:nth-of-type(3) {
            top: 160px;
            left: 120px;
        }
        &:nth-of-type(4) {
            top: 160px;
            right: 120px;
        }
        &:nth-of-type(5) {
            top: 320px;
            left: 210px;
        }
        &:nth-of-type(6) {
            top: 320px;
            right: 210px;
        }
        &:nth-of-type(7) {
            top: 160px;
            left: 50%;
            transform: translateX(-50%);
        }
        div{
            background: #ffffff;
            border-radius: 8px;
            box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
            // transform: scale(0.4);
            img{
                width: 100%;
            }
        }
    }
}

// custom 客户
@keyframes customScroll {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-2640px, 0, 0);
    }
}
.horizontal-scroll {
    white-space: nowrap;
    animation: customScroll 40s linear infinite;
    will-change: transform;
    &:hover {
        animation-play-state: paused;
        * {
            animation-play-state: paused;
        }
    }
    
}
.custom-rela{
    position: relative;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
    &::before,&::after {
        content: "";
        position: absolute;
        top: 0;
        width: 100px;
        height: 100%;
        pointer-events: none;
    }
    &::after {
        right: 0;
        background-image: linear-gradient(to right, rgba(0,0,0,0), #fff);
    }
    &::before {
        left: 0;
        z-index: 1;
        background-image: linear-gradient(to right, #fff, rgba(0,0,0,0));
    }
}
.homepage-custom{
    padding-bottom: 80px;
    .homepage-custom-flex{
        display: flex;
    }
    .custom-item{
        // width: 220px;
        // height: 120px;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05); 
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        img {
            transition: transform 0.5s;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}


// 发展历程 history
.homepage-history{
    background: #ffffff url(https://statres.wk1762.com/imgs/homepage-history-bg.png) no-repeat top center;
    background-size: contain;
    .rela{
        position: relative;
    }
    .chevronLeft{
        background-color: #0048FF;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        top: 8px;
        text-align: center;
        color: #fff;
        cursor: pointer;
        &.disabled{
            cursor: inherit;
            background-color: #c1c1c1;
        }
    }
    .chevronRight{
        background-color: #0048FF;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        right: -20px;
        text-align: center;
        color: #fff;
        cursor: pointer;
        &.disabled{
            cursor: inherit;
            background-color: #c1c1c1;
        }
    }
    .history-hidden {
        width: 100%;
        overflow: hidden;
    }
    .history-list{
        display: flex;
        position: relative;
        transform: translateX(0);
        // transition: transform 1s ease-in-out;
        .history-item{
            width: 20%;
            flex: 0 0 20%;
            padding-left: 6%;
            .title-year{
                font-size: 28px;
                color: #333333;
                font-weight: 400;
                margin-bottom: 55px;
            }
            .history-item-info{
                position: relative;
                font-size: 16px;
                color: #666666;
                .history-item-info-border-left{
                    position: absolute;
                    width: 1px;
                    background-color: rgba(0,72,255,0.20);
                    top: 0px;
                    bottom: 0px;
                    left: 5px;
                }
                ul{
                    list-style-type: none;
                    padding: 0;
                }
                li{
                    position: relative;
                    padding-left: 20px;
                    padding-bottom: 35px;
                    &::before{
                        position: absolute;
                        left: 0;
                        top: -2px;
                        content: '\2022';
                        color: #0048FF;
                        font-size: 28px;
                        line-height: 24px;
                        z-index: 3;
                    }
                    
                    span{
                        display: inline-block;
                        line-height: 24px;
                    }
                    &:last-child{
                       &::after{
                            content: '';
                            display: block;
                            width: 5px;
                            height: 100%;
                            background-color: #ffffff;
                            position: absolute;
                            top: 12px;
                            left: 3px;
                            z-index: 2;
                        } 
                    }
                }
            }
            &.active{
                .title-year{
                    color: #0048FF;
                    font-weight: 700;
                }
                .history-item-info{
                    color: #0048FF;
                    font-weight: 700;
                }
            }
        }
    }
}

// 企业荣誉  honor
.homepage-honor{
    margin-bottom: 20px;
    .honor-container{
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-right: 0px;
        padding-left: 0px;
    }
    .honor-container-flex{
        display: flex;
        padding-bottom: 20px;
        transform: translateX(0);
        transition: transform 1s ease-in-out;
        
    }
    .honor-list{
        flex: 0 0 100%;
        .honer-item{
            text-align: center;
            border-radius: 8px;
            position: relative;
            margin-bottom: 20px;
            cursor: pointer;
            img{
                width: 100%;
                object-fit: contain;
                object-position: center;
            }
            .honer-item-text{
                position: absolute;
                z-index: 1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0,72,255,0.90);
                border-radius: 8px;
                font-size: 18px;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: opacity 0.6s ease-in-out;

            }
            &:hover{
                .honer-item-text{
                    opacity: 1;
                }
            }
        }
    }
    .honor-btn{
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
        .honor-btn-item{
            width: 40px;
            height: 4px;
            background: #b2b2b2;
            border-radius: 8px;
            margin: 0 5px;
            cursor: pointer;
            transition: background 0.5s ease-in-out;
            &.active{
                background: #0048FF;
            }
        }
    }
}

// 董事会  directorate
.homepage-directorate{
    background-color: #f3f5f9;
    padding: 30px 0 ;
    .directorate-item{
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 60px;
        margin-bottom: 70px;
        h3{
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 12px;
        }
        h5{
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 20px;
        }
        p{
            font-size: 18px;
            font-weight: 400;
        }
        &:last-child{
            border: none;
        }
    }
}


// 核心业务页面  business-wrap-banner
.business-wrap-banner{
    min-height: 680px;
    overflow: hidden;
    text-align: center;
    position: relative;
    background-image: url(https://statres.wk1762.com/imgs/business-banner.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover
}
.business-tabs{
    margin-bottom: 60px;
    
    .business-tabs-flex{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        .tabs-item {
            flex: 1 0 25%;
            font-size: 28px;
            padding: 30px 0;
            color: #666666;
            text-align: center;
            cursor: pointer;
            &.active{
                font-weight: 600;
                color: #0048FF;
            }
    
        }
    }
    .active-border{
        height: 5px;
        margin-top: -3px;
        background-color: #0048FF;
        transition: width 0.3s ease, transform 0.3s ease; /* 添加过渡效果 */
    }
}

.business-tabs-connnet{
    .business-tabs-connnet-list{
        display: none;
        padding-bottom: 40px;
        h4{
            color: #333333;
            font-size: 36px;
            font-weight: 700;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #e6e6e6;;
        }
        p{
            color: #333333;
            font-size: 18px;
        }
        .connnet-list-item{
            display: flex;
            position: relative;
            padding: 40px;
            margin-bottom: 40px;
            background-color: #ffffff;
            .heading-icon{
                position: absolute;
                top: 0;
                left: 40px;
                color: #0048FF;
            }
            .left{
                flex: 1 0 50%;
                padding-right: 40px;

            }
            .right{
                flex: 1 0 50%;
                text-align: right;
                img{
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
        &.active{
            display: block;
        }
    }
}
.partner{
    background: #ffffff url(https://statres.wk1762.com/imgs/partner-bg.png) bottom center no-repeat;
    width: 100%;
    padding-top: 80px;
    .partner-list{
        display: none;
        padding-top: 50px;
        padding-bottom: 20px;
        // display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .partner-list-item{
            flex: 0 1 25%;
            text-align: center;
            object-fit: cover;
            object-position: center;
            border-radius: 8px;
            width: 25%;
            padding: 10px;
            img{
                width: 100%;
                box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05); 
            }
        }

        &.active{
            display: flex;
        }
    }
    .partner-tel{
        display: none;
        justify-content: center;
        align-items: center;
        h3{
            font-size: 28px;
            color: #333333;
            font-weight: 700;
            padding-left: 20px;
        }
        padding-top: 20px;
        padding-bottom: 40px;
        &.active{
            display: flex;
        }
    }
}


// 新闻 列表页面 news
.news-wrap-banner{
    min-height: 400px;
    overflow: hidden;
    text-align: center;
    position: relative;
    padding-top: 140px;
    cursor: pointer;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: -1;
    }
    h2{
        font-size: 64px;
        font-weight: 700;
        color: #ffffff;
        position: relative;
        z-index: 1;
        margin-bottom: 0;
    }
    p{
        font-size: 18px;
        color: #ffffff;
        position: relative;
        z-index: 1;
    }
}
.news-tabs{
    margin-bottom: 60px;
    
    .news-tabs-flex{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        .tabs-item {
            flex: 1 0 33.33%;
            font-size: 28px;
            padding: 30px 0;
            color: #666666;
            text-align: center;
            cursor: pointer;
            &.active{
                font-weight: 700;
                color: #0048FF;
            }
    
        }
    }
    .active-border{
        height: 5px;
        margin-top: -3px;
        background-color: #0048FF;
        transition: width 0.3s ease, transform 0.3s ease; /* 添加过渡效果 */
    }
}

.news-tabs-connnet{
    padding-bottom: 80px;
    .news-tabs-connnet-list{
        
        padding-bottom: 40px;
        h4{
            color: #333333;
            font-size: 28px;
            font-weight: 700;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        span{
            color: #999999;
            font-size: 16px;
        }
        p{
            color: #666666;
            font-size: 16px;
            padding-top: 10px;
        }
        .connnet-list-item{
            display: flex;
            position: relative;
            margin-bottom: 40px;
            cursor: pointer;
            .heading-icon{
                position: absolute;
                top: 0;
                left: 40px;
                color: #0048FF;
            }
            .left{
                width: 360px;
                height: 270px;
                img{
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    height: 100%;
                }
            }
            .right{
                flex: 1;
                width: 0;
                padding-left: 40px;
                padding-top: 5px;
                
            }
            &:hover {
                h4 {
                    color: #0048FF;
                }
            }
        }
    }
}
.wk-new-pagination{
    text-align: center;
    .ant-pagination-item-active{
        background-color: #0048FF;
        border-color: #0048FF;
        color: #fff;
    }
    .ant-pagination-prev{
        line-height: 30px;
        text-align: center;
        vertical-align: middle;
        list-style: none;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        outline: 0;
        cursor: pointer;
        &.ant-pagination-disabled{
            opacity: 0.4;
        }
        a{
            padding: 0 6px;
        }
    }
    .ant-pagination-next{
        line-height: 30px;
        text-align: center;
        vertical-align: middle;
        list-style: none;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        outline: 0;
        cursor: pointer;
        &.ant-pagination-disabled{
            opacity: 0.4;
        }
        a{
            padding: 0 6px;
        }
    }
}

.news-info{
    padding-top: 120px;
    padding-bottom: 40px;
    .news-info-title{
        font-size: 36px;
        font-weight: 700;
        color: #333333;
        text-align: center;
    }
    .news-info-tims{
        color: #666666;
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 10px;
        margin-bottom: 30px;
        text-align: center;
    }
    img {
        max-width:  100%;
    }
}
// 加入我们 job
.jobs-wrap-banner{
    min-height: 400px;
    overflow: hidden;
    text-align: center;
    position: relative;
    background-image: url(https://statres.wk1762.com/imgs/job-bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h2{
        font-size: 64px;
        font-weight: 700;
        margin-bottom: 0;
        color: #ffffff;
        text-align: left;
    }
    p{
        font-size: 16px;
        color: #ffffff;
        text-align: left;
    }
}
@keyframes locationHover {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.environment{
    padding-top: 30px;
    .environment-null{
        width: 100%;
        height: 260px;
        background-color: #ffffff;
    }
    .location{
        display: flex;
        justify-content: space-between;
        transform: translateY(-240px);
        
        div{
            
            &.location-item{
                flex: 0 1 32%;
                cursor: pointer;
                transform: translateY(100px);
                transition: transform .4s ease-in-out;
                position: relative;
                border-radius: 8px;
            }
            &.active{
                .image-overlay {
                    opacity: 0;
                    // animation: locationHover 0.4s ease-out 0.7s;
                    // animation-fill-mode: forwards;
                }
            }
            &:hover{
                .image-overlay {
                    opacity: 0;
                }
            }
            .image-overlay {
                position: absolute;
                top: 0px;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.7); /* 使用 rgba 来设置颜色和透明度 */
                border-radius: 8px;
                opacity: 1; /* 默认隐藏 */
                transition: opacity 0.6s ease; /* 添加过渡效果 */
                z-index: 2;
            }
            .location-img{
                width: 100%;
                border-radius: 8px;
            }
            .location-name{
                position: absolute;
                top: 50px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                color: #ffffff;
                font-size: 36px;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                
            }
        }
    }
    .corporation-environmen{
        margin-top: -80px;
        padding-bottom: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .ant-image{
            flex: 0 0 24%;
            margin-bottom: 10px;
            img{
                width: 100%;
            }
        }
        .ant-image-img{
            border-radius: 8px;
        }
    }
}
.jobs-fl{
    .fl-list{
        padding-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .fl-list-item{
            flex: 1 0 20%;
            margin-bottom: 60px;
            text-align: center;
            p{
                color: #333333;
                font-size: 18px;
            }
        }
    }
}
.jobs-info{
    position: relative;
    .container {
        position: relative;
        z-index: 9;
    }
    .ab_left{
        width: 180px;
        height: 180px;
        position: absolute;
        background-image: url(https://statres.wk1762.com/imgs/jb-bg-2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        bottom: 180px;
    }
    .ab_right{
        width: 180px;
        height: 180px;
        position: absolute;
        background-image: url(https://statres.wk1762.com/imgs/jb-bg-1.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        top: 50px;
        right: 0;
    }
}
.jobs-form{
    width: 100%;
    .ant-form-item{
        flex: 1 0 30%;
        background-color: #fff;
        margin-right: 0;
        border-radius: 8px;
        &:nth-of-type(2) {
            margin: 0 20px;
        }
    }
}
.popup{
    .ant-select-item{
        padding: 14px 12px;
        &.ant-select-item-option-selected {
            color: #0048FF;
            background: #f8f9fa;
        }
    }
}
.recruit{
    padding-bottom: 100px;
    position: relative;
    z-index: 9;
    padding-top: 20px;
    .recruit-item{
        background-color: #ffffff;
        border-radius: 8px;
        margin-bottom: 20px;
        .recruit-item-title{
            cursor: pointer;
            padding: 20px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            position: relative;
            .recruit-close{
                display: none;
                position: absolute;
                z-index: 1;
                right: 20px;
                top: 20px;
                color: #fff;
                cursor: pointer;
                transform: rotate(0);
                transition: transform .4s ease-in-out;
                &:hover{
                    transform: rotate(180deg)
                }
            }
            span{
                flex: 0 1 22%;
                color: #333333;
                font-size: 18px;
                font-weight: 700;
                cursor: pointer;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
                &:first-child{
                    text-align: left;
                }
                &:last-child{
                    color: #999999;
                    text-align: right;
                }
                &.right{
                    color: #999999;
                    text-align: right;
                }
            }
        }
        .recruit-item-con{
            overflow: hidden;
            max-height: 0;
            // transition: max-height 0.4s ease-out;
            padding: 0;
            p {
                color: #666666;
                font-size: 16px;
                padding-bottom: 20px;
                &.local-tips{
                    padding-top: 20px;
                }
            }
            h5 {
                color: #333333;
                font-size: 16px;
                font-weight: 700;
                border-left: 4px solid #0048FF;
                padding-left: 12px;
            }
            transition: all .6s ease-in-out;
        }
        &.active{
            .recruit-item-title{
                background-color: #0048FF;
                span{
                    opacity: 0;
                    color: #ffffff;
                    &:first-child{
                        opacity: 1;
                    }
                }
            }
            .recruit-close{
                display: block;
            }
            .recruit-item-con{
                padding: 0 20px 20px 20px;
                // max-height: none;
            }
        }
    }
}


// 投资者关系 investor
// .demo-loadmore-list {
//     // min-height: 544px;
// }
// #w-zgwj {
//     .file-list {
//         min-height: 276px;
//     }
// }
// #w-cwbg {
//     .finance-list {
//         min-height: 1026px;
//     }
// }
.investor-wrap-banner {
    min-height: 400px;
    overflow: hidden;
    text-align: center;
    position: relative;
    background-image: url(https://statres.wk1762.com/imgs/investor-bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2{
        font-size: 36px;
        font-weight: 700;
        color: #ffffff;
        display: inline-block;
        &:hover {
            color: #1890ff;
        }
    }
    p{
        font-size: 18px;
        color: #ffffff;
    }
}
// 企业管治
.governance{
    padding: 80px 0;
    display: flex;

    .logo{
        flex: 1 0 420px;
        background: #F8F9FA url(https://statres.wk1762.com/imgs/black-logo.png) center no-repeat;
        background-size: 80%;
        min-height: 420px;
    }
    .governance-con{
        flex: auto;
        padding-left: 100px;
        h4{
            font-size: 36px;
            font-weight: 700;
            color: #333333;
        }
        p{
            font-size: 18px;
            color: #333333;
            line-height: 32px;
        }
    }
}
.bg-pic-1{
    background: url(https://statres.wk1762.com/imgs/bg-pic-1.png) center no-repeat;
    background-size: cover;
    padding: 80px 0;
    .investor-product-header{
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 20px;
    }
    .ant-list-item-meta-title{
        color: #ffffff;
    }
    a{
        color: #ffffff;
    }
    .times{
        color: #999999;
    }
    .ant-list-split .ant-list-item{
        border-bottom: none;
        &:last-child{
            border-bottom: none !important;
        }
    }
}
.bg-pic-2{
    padding: 80px 0;
    .times{
        color: #999999;
    }

    .news-tabs{
        margin-bottom: 50px;
        
        .news-tabs-flex{
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e6e6e6;
            .tabs-item {
                flex: 1 0 50%;
                font-size: 28px;
                padding: 30px 0;
                color: #666666;
                text-align: center;
                cursor: pointer;
                &.active{
                    font-weight: 700;
                    color: #0048FF;
                }
        
            }
        }
        .active-border{
            height: 5px;
            margin-top: -3px;
            background-color: #0048FF;
            transition: width 0.3s ease, transform 0.3s ease; /* 添加过渡效果 */
        }
    }
    
    // .news-tabs-connnet{
    //     padding-bottom: 80px;
    //     .news-tabs-connnet-list{
            
    //         padding-bottom: 40px;
    //         h4{
    //             color: #333333;
    //             font-size: 28px;
    //             font-weight: 700;
    //             overflow:hidden;
    //             white-space: nowrap;
    //             text-overflow: ellipsis;
    //         }
    //         span{
    //             color: #999999;
    //             font-size: 16px;
    //         }
    //         p{
    //             color: #666666;
    //             font-size: 16px;
    //             padding-top: 10px;
    //         }
    //         .connnet-list-item{
    //             display: flex;
    //             position: relative;
    //             margin-bottom: 40px;
    //             .heading-icon{
    //                 position: absolute;
    //                 top: 0;
    //                 left: 40px;
    //                 color: #0048FF;
    //             }
    //             .left{
    //                 flex: 1 0 30%;
    //                 min-height: 270px;
    //                 img{
    //                     width: 100%;
    //                     object-fit: cover;
    //                     object-position: center;
    //                 }
    
    //             }
    //             .right{
    //                 flex: auto;
    //                 padding-left: 40px;
    //                 padding-top: 5px;
                    
    //             }
    //         }
    //     }
    // }
}

.bg-pic-3{
    background: url(https://statres.wk1762.com/imgs/bg-pic-3.png) center no-repeat;
    background-size: cover;
    padding: 80px 0;
    .investor-product-header{
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 20px;
    }
    .times{
        color: #666666;
    }
    .ant-list-split .ant-list-item{
        border-bottom: none;
        &:last-child{
            border-bottom: none !important;
        }
    }
}

.bg-pic-4{
    padding: 80px 0;
    .times{
        color: #999999;
    }
    .ant-list-split .ant-list-item{
        border-bottom: none;
        &:last-child{
            border-bottom: none !important;
        }
    }
}

// 招股文件
.file{
    padding: 80px 0;
    .home-h3-title{
        margin-bottom: 60px;
    }
    .file-list{
        display: flex;
        .file-item{
            flex: 0 1 20%;
            margin: 0 10px;
            padding: 20px 20px 140px 20px;
            background: #F8F9FA url(https://statres.wk1762.com/imgs/file-bg.png) center bottom no-repeat;
            background-size: cover;
            border-radius: 8px;
            span{
                color: #999999;
                font-size: 16px;
            }
            p{
                color: #333333;
                font-size: 18px;
                margin-bottom: 40px;
            }
            a{
                color: #0048FF;
                
            }
        }
    }
}

.finance{
    background: #f8f9fa; 
    padding: 80px 0;
    .home-h3-title{
        margin-bottom: 60px;
    }
    .finance-list{
        display: flex;
        flex-wrap: wrap;
        .finance-item{
            flex: 0 1 25%;
            text-align: center;
            padding: 0 10px;
            img{
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
            p{
                color: #333333;
                font-size: 18px;
                text-align: center;
                padding-top: 15px;
            }
        }
    }
}
@media (max-width: 1200px) {
    .homepage-media{
        background: #E8EDF7 url(https://statres.wk1762.com/imgs/homepage-media-bg.png) no-repeat top center;
        background-size: cover;
        min-height: 700px;
        .media-item{
            width: 220px;
            position: absolute;
            &:nth-of-type(1) {
                top: 0;
                left: 180px;
            }
            &:nth-of-type(2) {
                top: 0;
                right: 180px;
            }
            &:nth-of-type(3) {
                top: 160px;
                left: 50px;
            }
            &:nth-of-type(4) {
                top: 160px;
                right: 50px;
            }
            &:nth-of-type(5) {
                top: 320px;
                left: 140px;
            }
            &:nth-of-type(6) {
                top: 320px;
                right: 140px;
            }
            &:nth-of-type(7) {
                top: 160px;
                left: 50%;
                transform: translateX(-50%);
            }
            div{
                background: #ffffff;
                border-radius: 8px;
                box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
                // transform: scale(0.4);
                img{
                    width: 100%;
                }
            }
        }
    }
}