//common
@import '~antd/dist/antd.css';
.m-homepage-product-header{
    padding: 0;
    .m-home-h3-title{
        padding-bottom: 0;
    }
    p{
        font-size: 14px;
        text-align: center;
        color: #666666;
    }
}
.m-home-h3-title{
    text-align: center;
    font-weight: 700;
    padding-bottom: 10px;
    font-size: 20px;
}
.bg-gray{
    background: #f8f9fa;
}
.m-product-item{
    width: 100%;
    // background-color: blue;
    border-radius: 8px;
    color: #fff;
    padding: 16px;
    background-size: contain;
    margin-top: 12px;
    &.product-item-bg-1 {
        
        background: #0048FF url(https://statres.wk1762.com/imgs/m-product-item_1.png) no-repeat;
        background-position: top center;
        background-size: cover;
        .m-home-flex{
            display: flex;
            justify-content:space-between;
            .right{
                padding-right: 60px;
            }
        }
    }
    &.product-item-bg-2 {
        background: #2AC082 url(https://statres.wk1762.com/imgs/m-product-item_2.png) no-repeat;
        background-position: top center;
        background-size: cover;
    }
    &.product-item-bg-3 {
        background: #006FFF url(https://statres.wk1762.com/imgs/m-product-item_3.png) no-repeat;
        background-position: top center;
        background-size: cover;

    }
    &.product-item-bg-4 {
        background: #009FC2 url(https://statres.wk1762.com/imgs/m-product-item_4.png) no-repeat top right;
        background-position: top center;
        background-size: cover;
    }
    &.product-item-bg-5 {
        background: #536377 url(https://statres.wk1762.com/imgs/m-product-item_5.png) no-repeat top right;
        background-position: top center;
        background-size: cover;
    }
    h5{
        color: #fff;
        font-weight: 700;
        font-size: 18px;
    }
    p{
        font-size: 14px;
        font-weight: 400;
    }
    .product-item-number{
        .value{
            font-size: 28px;
            font-weight: 700;
            line-height: 28px;
        }
        .subscript{
            font-size: 14px;
            padding-left: 6px;
            font-weight: 400;
        }
    }
}

.rela{
    position: relative;
}
.m-back{
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    cursor: pointer;
}
.m-drawer{
    .ant-drawer-content{
        background-color: #212121;
    }
    .ant-drawer-body{
        padding: 0;
    }
    .m-drawer-menu{

    }
    .m-header-wrapper{
        width: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        position: initial;
    }
    .m-collapse{
        background-color: #212121;
        .ant-collapse{
            background-color: #212121;
            .ant-collapse-header-text{
                color: #fff;
                font-weight: 700;
                font-size: 14px;
            }
            .ant-collapse-expand-icon{
                color: #fff;
            }
            .ant-collapse-content-box{
                background-color: #212121;
                ul{
                    margin: 0;
                    padding: 0;
                    li{
                        padding: 10px 16px;
                        margin: 0;
                        list-style: none;
                        span{
                            color: #fff;
                            font-size: 12px;
                        }
                        &.active{
                            span{
                                color: #fff;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
        .ant-collapse > .ant-collapse-item {
            border-bottom: 1px solid #e6e6e6;
        }
    }
}
// header
.m-header-wrapper{
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    .m-header-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .m-wk_logo{
            flex: 0 1 200px;
            height: 50px;
            background-image: url(../../public/imgs/logo.png);
            background-repeat: no-repeat;
            background-position: left center;
            background-size: contain;
        }
        .m-wk_translate{
            flex: 0 0 120px;
            width: 120px;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .m-wk_translate_item{
                margin: 0 8px;
                color: #B2B2B2;
                cursor: pointer;
                &.active {
                    font-weight: 700;
                    color: #fff;
                }
            }
            .bi-list{
                color: #fff;
                cursor: pointer;
            }
        }
    }
}

// footer
.m-wk_footer{
    background-color: #212121;
    color: #B2B2B2;
    padding: 40px 0;
    h6{
        font-size: 16px;
        color: #ffffff;
        padding-bottom: 8px;
    }
    .m-wk_footer-contact{  
        .wk_footer-contact-list{
            .contact-list-item{
                .contact-list-item-flex{
                    display: flex;
                    align-items: top;
                    padding-bottom: 10px;
                    
                    span {
                        padding-right: 8px;
                        &.telephone{
                            padding-right: 9px;
                            padding-left: 2px;
                        }
                    }
                    p{
                        font-size: 14px;
                    }
                }
                p{
                    margin: 0;
                }
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                margin-bottom: 10px;
                &:last-child{
                    border-bottom:none;
                }
                .m-wk_footer-connent-qr{
                    text-align: center;
                    img{
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .wk_footer-copyright{
        font-size: 14px;
        text-align: left;
    }
}

// home

//banner
.m-homepage-video-wrap {
    height: 320px;
    min-height: 320px;
    text-align: center;
    position: relative;
    background-image: url(https://statres.wk1762.com/imgs/video-poster-m.png);
    background-size: 100% 100%;
    // #wk-video{
    //     width: 100%;
    //     height: auto;
    // } 
    .m-overlay {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        padding: 0 6px;
        .m-overlay-wrapper{
            width: 100%;
            background-color: #F8F9FA;
            border-radius: 8px;
        }
        .m-overlay-content{
            display: flex;
            align-items: top;
            justify-content: space-around;
            padding: 20px 0;
            .m-overlay-item {
                width: 33.33%;
                flex: 0 1 33.33%;
                padding: 0 16px;
                &:nth-of-type(2) {
                    border-left: 1px solid #E6E6E6;
                    border-right: 1px solid #E6E6E6;
                }
                h4{
                    color: #333333;
                    font-size: 14px;
                    font-weight: 700;
                }
                p{
                    color: #666666;
                    font-size: 14px;
                }
            }
        }
        
    }
}

//product 产品
.m-homepage-product{
    padding: 100px 0 30px 0;
}
.m-homepage-product-header{
    p{
        padding: 0 16px;
        font-size: 14px;
        text-align: center;
        color: #666666;
    }
}


.m-homepage-product-data{
    padding: 40px 0;
    background: #010411 url(https://statres.wk1762.com/imgs/m-homepage-product-data-bg.png) no-repeat right center;
    background-size: contain;
    .m-home-h4-title{
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        text-align: left;
        padding-bottom: 20px;
    }
    .m-product-item{
        padding: 0px;
    }
}


// media 媒体
// 竖向间隔 0 160 320     横向 320 120 160
.m-homepage-media{
    background: #E8EDF7 url(https://statres.wk1762.com/imgs/m-homepage-media-bg.png) no-repeat center;
    background-size: cover;
    padding-bottom: 20px;
    .m-homepage-product-header{
        padding-top: 40px;
        padding-bottom: 20px;
    }
    .m-media-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .m-media-item{
        flex: 0 1 50%;
        padding: 0 6px;
        margin-bottom: 12px;
        img{
            width: 100%;
            border-radius: 8px;
            box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
        }
    }
}

// custom 客户
@keyframes m-customScroll {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-1342px, 0, 0);
    }
}
.m-horizontal-scroll {
    white-space: nowrap;
    animation: m-customScroll 40s linear infinite;
    will-change: transform;
}
.custom-rela{
    position: relative;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
}
.m-homepage-custom{
    padding-top: 40px;
    padding-bottom: 40px;
    .m-homepage-custom-flex{
        display: flex;
    }
    .m-custom-item{
        // width: 220px;
        // height: 120px;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05); 
        margin-right: 12px;
        margin-top: 12px;
        img{
            width: 110px;
            height: 60px;
            object-fit: contain;
            object-position: center;
        }
    }
}


// 发展历程 history
.m-homepage-history{
    padding-top: 40px;
    padding-bottom: 40px;
    background: #ffffff url(https://statres.wk1762.com/imgs/m-homepage-history-bg.png) no-repeat top center;
    background-size: contain;
    overflow: hidden;
    // .m-history-flex{
    //      width: 100%;
    //     overflow-x: scroll;   
    //     display: flex;
    //     width: 100%;
    // }
    .m-history-list{
        width: 100%;
        overflow-x: scroll;
        display: flex;
        position: relative;
        .m-chevronLeft{
            background-color: #0048FF;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 8px;
            text-align: center;
            color: #fff;
            cursor: pointer;
        }
        .m-chevronRight{
            background-color: #0048FF;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            right: 0;
            text-align: center;
            color: #fff;
            cursor: pointer;
        }
        
        .m-history-item{
            width: 100%;
            flex: 0 0 100%;
            .m-title-year{
                font-size: 22px;
                color: #333333;
                font-weight: 400;
                margin-bottom: 25px;
                
            }
            .m-history-item-info{
                position: relative;
                font-size: 14px;
                color: #666666;
                .m-history-item-info-border-left{
                    position: absolute;
                    width: 1px;
                    background-color: rgba(0,72,255,0.20);
                    top: 0px;
                    bottom: 0px;
                    left: 5px;
                }
                ul{
                    list-style-type: none;
                    padding: 0;
                }
                li{
                    position: relative;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 35px;
                    &::before{
                        position: absolute;
                        left: 0;
                        top: -2px;
                        content: '\2022';
                        color: #0048FF;
                        font-size: 28px;
                        line-height: 24px;
                        z-index: 3;
                    }
                    
                    span{
                        display: inline-block;
                        line-height: 24px;
                    }
                    &:last-child{
                       &::after{
                            content: '';
                            display: block;
                            width: 5px;
                            height: 100%;
                            background-color: #ffffff;
                            position: absolute;
                            top: 12px;
                            left: 3px;
                            z-index: 2;
                        } 
                    }
                }
            }
            &.active{
                .m-title-year{
                    color: #0048FF;
                    font-weight: 700;
                }
                .m-history-item-info{
                    color: #0048FF;
                    font-weight: 700;
                }
            }
        }
    }
}

// 企业荣誉  honor
.m-homepage-honor{
    padding: 40px 0;
    .m-honor-container{
        width: 100%;
        overflow-x: scroll;
        padding-right: 46px;
        padding-left: 46px;
    }
    .m-honor-container-flex{
        display: flex;
        padding-top: 10px;
        padding-bottom: 20px;
        transform: translateX(0);
        transition: transform 1s ease-in-out;
    }
    .m-honer-item-null {
        flex: 0 0 35px;
    }
    .m-honer-item{
        flex: 0 0 100%;
        text-align: center;
        border-radius: 8px;
        position: relative;
        margin-bottom: 20px;
        margin-right: 12px;
        cursor: pointer;
        img{
            width: 100%;
            object-fit: contain;
            object-position: center;
        }
        .m-honer-item-text{
            padding-top: 12px;
            font-size: 14px;
            color: #333333;

        }
    }
}

// 董事会  directorate
.m-homepage-directorate{
    background-color: #f3f5f9;
    padding: 30px 0 ;
    .m-directorate-item{
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 20px;
        h3{
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 12px;
        }
        h5{
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 20px;
        }
        p{
            font-size: 14px;
            font-weight: 400;
        }
        &:last-child{
            border: none;
        }
    }
}


// 核心业务页面  business-wrap-banner
.m-business-wrap-banner{
    min-height: 200px;
    overflow: hidden;
    text-align: center;
    position: relative;
    background-image: url(https://statres.wk1762.com/imgs/m-business-banner.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover
}
.m-business-tabs{
    margin-bottom: 30px;
    .m-business-tabs-flex{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        .m-tabs-item {
            flex: 1 0 25%;
            font-size: 14px;
            padding: 15px 0;
            color: #666666;
            text-align: center;
            cursor: pointer;
            &.active{
                font-weight: 700;
                color: #0048FF;
            }
    
        }
    }
    .m-active-border{
        height: 5px;
        margin-top: -3px;
        background-color: #0048FF;
        transition: width 0.3s ease, transform 0.3s ease; /* 添加过渡效果 */
    }
}

.m-business-tabs-connnet{
    .m-business-tabs-connnet-list{
        display: none;
        padding-bottom: 40px;
        h4{
            color: #333333;
            font-size: 20px;
            font-weight: 700;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #e6e6e6;;
        }
        p{
            color: #333333;
            font-size: 14px;
        }
        .m-connnet-list-item{
            position: relative;
            padding: 40px 16px 16px 16px;
            margin-bottom: 20px;
            background-color: #ffffff;
            cursor: pointer;
            .bi-bookmark-fill{
                position: absolute;
                top: 0;
                left: 16px;
                color: #0048FF;
                // transform: scale(0.7);
            }
            .left{
            }
            .right{
                img{
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    max-height: 100%;
                }
            }
        }
        &.active{
            display: block;
        }
    }
}
.m-partner{
    background-image: url(https://statres.wk1762.com/imgs/m-partner-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 40px;
    .m-partner-list{
        padding-top: 10px;
        display: none;
        justify-content: flex-start;
        flex-wrap: wrap;
        .m-partner-list-item{
            flex: 0 1 50%;
            text-align: center;
            object-fit: cover;
            object-position: center;
            border-radius: 8px;
            width: 50%;
            padding: 6px;
            img{
                width: 100%;
                box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05); 
            }
        }
        &.active{
            display: flex;
        }
    }
    .m-partner-tel{
        display: none;
        justify-content: center;
        align-items: center;
        img{
            transform: scale(0.6);
        }
        h3{
            font-size: 14px;
            color: #333333;
            font-weight: 700;
        }
        padding-top: 10px;
        &.active{
            display: flex;
        }
    }
}


// 新闻 列表页面 news
.m-news-wrap-banner{
    min-height: 200px;
    overflow: hidden;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
        object-position: center;
    }
    h2{
        font-size: 28px;
        font-weight: 700;
        color: #ffffff;
        padding-top: 80px;
        position: relative;
        z-index: 1;
        margin-bottom: 0;
    }
    p{
        font-size: 14px;
        color: #ffffff;
        padding-left: 80px;
        padding-right: 80px;
        position: relative;
        z-index: 1;
    }
}
.m-news-tabs{
    margin-bottom: 30px;
    .m-news-tabs-flex{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        .m-tabs-item {
            flex: 1 0 33.33%;
            font-size: 14px;
            padding: 15px 0;
            color: #666666;
            text-align: center;
            cursor: pointer;
            &.active{
                font-weight: 700;
                color: #0048FF;
            }
    
        }
    }
    .m-active-border{
        height: 5px;
        margin-top: -3px;
        background-color: #0048FF;
        transition: width 0.3s ease, transform 0.3s ease; /* 添加过渡效果 */
    }
}

.m-news-tabs-connnet{
    padding-bottom: 20px;
    .m-news-tabs-connnet-list{
        
        padding-bottom: 40px;
        h4{
            color: #333333;
            font-size: 18px;
            font-weight: 700;
            // overflow:hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
        }
        span{
            color: #999999;
            font-size: 14px;
        }
        .m-connnet-list-item{
            display: flex;
            position: relative;
            margin-bottom: 20px;
            .left{
                
                flex: 0 0 120px;
                height: 80px;
                img{
                    width: 120px;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 8px;
                    max-height: 100%;
                }

            }
            .right{
                flex: auto;
                padding-right: 20px;
                padding-top: 5px;
                
            }
        }
    }
}
.wk-new-pagination{
    text-align: center;
    .ant-pagination-item-active{
        background-color: #0048FF;
        border-color: #0048FF;
        color: #fff;
    }
    .ant-pagination-prev{
        line-height: 30px;
        text-align: center;
        vertical-align: middle;
        list-style: none;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        outline: 0;
        cursor: pointer;
        &.ant-pagination-disabled{
            opacity: 0.4;
        }
        a{
            padding: 0 6px;
        }
        margin-right: 10px !important;
    }
    .ant-pagination-next{
        line-height: 30px;
        text-align: center;
        vertical-align: middle;
        list-style: none;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        outline: 0;
        cursor: pointer;
        margin-left: 10px !important;
        &.ant-pagination-disabled{
            opacity: 0.4;
        }
        a{
            padding: 0 6px;
        }
    }
}
.m-news-info{
    padding-top: 70px;
    .news-info-title{
        font-size: 18px;
        font-weight: 700;
        color: #333333;
        text-align: center;
    }
    .news-info-tims{
        color: #666666;
        font-size: 12px;
        font-weight: 400;
        border: 1px solid #e6e6e6;
        padding: 4px 0 7px;
        margin-bottom: 20px;
        text-align: center;
    }
    img {
        max-width: 100%;
    }
}
// 加入我们 job
.m-jobs-wrap-banner{
    min-height: 200px;
    overflow: hidden;
    text-align: center;
    position: relative;
    background-image: url(https://statres.wk1762.com/imgs/m-job-bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h2{
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 0;
        padding-top: 50px;
        color: #ffffff;
        text-align: center;
    }
    p{
        font-size: 14px;
        color: #ffffff;
        text-align: center;
    }
}
@keyframes locationHover {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.m-environment{
    padding-top: 40px;
    .m-overflow{
        width: 100%;
        overflow: hidden;
    }
    .m-location{
        margin-top: 20px;
        margin-bottom: 10px;
        display: flex;
        // justify-content: center;
        padding: 0 50px 15px;
        overflow-x: scroll;
        // min-height: 320px;
        .center{
            margin-left: 12px;
            margin-right: 12px;
        }
        .m-location-img {
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.7);
                transition:  background-color 0.3s;
            }
            &.active {
                &::after {
                    background-color: rgba(0, 0, 0, 0);
                }
            }
        }
        div{
            flex: 0 0 100%;
            cursor: pointer;
            transition: transform .4s ease-in-out;
            position: relative;
            border-radius: 8px;
            margin: 0 12px;
            &.active{
                .image-overlay {
                    opacity: 0;
                    
                }
            }
            img{
                width: 100%;
            }
            .m-location-name{
                position: absolute;
                top: 30px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                color: #ffffff;
                font-size: 24px;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                
            }
        }
    }
    .m-corporation-environmen{
        padding-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // min-height: 764px;
        .ant-image{
            padding: 0 10px;
            flex: 0 0 50%;
            margin:  8px 0;
        }
        .ant-image-img{
            border-radius: 8px;
        }
    }
}
.m-jobs-fl{
    padding: 40px 0;
    .m-home-h3-title{
        padding-bottom: 20px;
    }
    .m-fl-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        min-height: 286px;
        .m-fl-list-item{
            flex: 0 1 25%;
            text-align: center;
            overflow: hidden;
            p{
                color: #333333;
                font-size: 12px;
            }
        }
    }
}
.m-jobs-info{
    padding: 40px 0;
    position: relative;
    .m-home-h3-title{
        margin-bottom: 20px;
    }
    .m-ab_left{
        width: 90px;
        height: 90px;
        position: absolute;
        background-image: url(https://statres.wk1762.com/imgs/jb-bg-2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        bottom: 30px;
    }
    .m-ab_right{
        width: 70px;
        height: 70px;
        position: absolute;
        background-image: url(https://statres.wk1762.com/imgs/jb-bg-1.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        top: 20px;
        right: 0;
    }
}
.m-jobs-form{
    width: 100%;
    .ant-form-item{
        
        background-color: #fff;
        margin-right: 0;
        border-radius: 8px;
        &:nth-of-type(1) {
            width: 49%;
            margin-bottom: 2%;
        }
        &:nth-of-type(2) {
            width: 49%;
            margin-left: 2%;
            margin-bottom: 2%;
        }
        &:nth-of-type(3) {
            width: 100%;
        }
    }
}
.popup{
    .ant-select-item{
        padding: 14px 12px;
    }
}
.m-recruit{
    padding-bottom: 10px;
    position: relative;
    z-index: 9;
    padding-top: 20px;
    .m-recruit-item{
        background-color: #ffffff;
        border-radius: 8px;
        margin-bottom: 20px;
        .m-recruit-item-title{
            cursor: pointer;
            padding: 20px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            position: relative;
            .m-recruit-close{
                display: none;
                position: absolute;
                z-index: 1;
                right: 20px;
                top: 20px;
                color: #fff;
                cursor: pointer;
                transform: rotate(0);
                transition: transform .4s ease-in-out;
                &:hover{
                    transform: rotate(180deg)
                }
            }
            span{
                flex: 0 1 33%;
                color: #333333;
                font-size: 14px;
                font-weight: 700;
                cursor: pointer;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
                &:first-child{
                    text-align: left;
                }
                &:last-child{
                    color: #999999;
                    text-align: right;
                }
            }
        }
        .m-recruit-item-con{
            overflow: hidden;
            max-height: 0;
            // transition: max-height 0.4s ease-out;
            padding: 0;
            p {
                color: #666666;
                font-size: 14px;
                &.local-tips{
                    padding-top: 12px;
                }
            }
            h5 {
                color: #333333;
                font-size: 14px;
                font-weight: 700;
                border-left: 4px solid #0048FF;
                padding-left: 12px;
            }
            transition: all .6s ease-in-out;
        }
        &.active{
            .m-recruit-item-title{
                background-color: #0048FF;
                span{
                    opacity: 0;
                    color: #ffffff;
                    &:first-child{
                        opacity: 1;
                    }
                }
            }
            .m-recruit-close{
                display: block;
            }
            .m-recruit-item-con{
                padding: 0 20px 20px 20px;
                // max-height: none;
            }
        }
    }
}

@media (max-width: 768px) {
    #w-zgwj {
        .file-list {
            min-height: 276px;
        }
    }
    #w-cwbg {
        .m-finance-list {
            min-height: 790px;
        }
    }
}

// 投资者关系 investor
.m-investor-wrap-banner {
    min-height: 200px;
    overflow: hidden;
    text-align: center;
    position: relative;
    background-image: url(https://statres.wk1762.com/imgs/m-investor-bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2{
        font-size: 28px;
        padding-top: 50px;
        font-weight: 700;
        color: #ffffff;
    }
    p{
        font-size: 18px;
        color: #ffffff;
    }
}
// 企业管治
.m-governance{
    padding: 40px 0;
    display: flex;
    .governance-con{
        h4{
            font-size: 20px;
            font-weight: 700;
            color: #333333;
            text-align: center;
        }
        p{
            font-size: 14px;
            color: #333333;
            line-height: 24px;
        }
    }
}
.bg-pic-1{
    background: url(https://statres.wk1762.com/imgs/bg-pic-1.png) center no-repeat;
    background-size: cover;
    padding: 40px 0;
    .investor-product-header{
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 10px;
    }
    .ant-list-item-meta-title{
        color: #ffffff;
    }
    a{
        color: #ffffff;
    }
    .times{
        color: #999999;
    }
    .ant-list-split .ant-list-item{
        border-bottom: none;
        &:last-child{
            border-bottom: none !important;
        }
    }
}
.bg-pic-2{
    padding: 40px 0;
    .times{
        color: #999999;
    }

    .m-news-tabs{
        margin-bottom: 25px;
        
        .m-news-tabs-flex{
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e6e6e6;
            .m-tabs-item {
                flex: 1 0 50%;
                font-size: 14px;
                padding: 15px 0;
                color: #666666;
                text-align: center;
                cursor: pointer;
                &.active{
                    font-weight: 700;
                    color: #0048FF;
                }
        
            }
        }
        .m-active-border{
            height: 5px;
            margin-top: -3px;
            background-color: #0048FF;
            transition: width 0.3s ease, transform 0.3s ease; /* 添加过渡效果 */
        }
    }
    
    // .news-tabs-connnet{
    //     padding-bottom: 80px;
    //     .news-tabs-connnet-list{
            
    //         padding-bottom: 40px;
    //         h4{
    //             color: #333333;
    //             font-size: 28px;
    //             font-weight: 700;
    //             overflow:hidden;
    //             white-space: nowrap;
    //             text-overflow: ellipsis;
    //         }
    //         span{
    //             color: #999999;
    //             font-size: 16px;
    //         }
    //         p{
    //             color: #666666;
    //             font-size: 16px;
    //             padding-top: 10px;
    //         }
    //         .connnet-list-item{
    //             display: flex;
    //             position: relative;
    //             margin-bottom: 40px;
    //             .heading-icon{
    //                 position: absolute;
    //                 top: 0;
    //                 left: 40px;
    //                 color: #0048FF;
    //             }
    //             .left{
    //                 flex: 1 0 30%;
    //                 min-height: 270px;
    //                 img{
    //                     width: 100%;
    //                     object-fit: cover;
    //                     object-position: center;
    //                 }
    
    //             }
    //             .right{
    //                 flex: auto;
    //                 padding-left: 40px;
    //                 padding-top: 5px;
                    
    //             }
    //         }
    //     }
    // }
}

.bg-pic-3{
    background: url(https://statres.wk1762.com/imgs/bg-pic-3.png) center no-repeat;
    background-size: cover;
    padding: 40px 0;
    .investor-product-header{
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 20px;
    }
    .times{
        color: #666666;
    }
    .ant-list-split .ant-list-item{
        border-bottom: none;
        &:last-child{
            border-bottom: none !important;
        }
    }
}

.bg-pic-4{
    padding: 40px 0;
    .times{
        color: #999999;
    }
    .ant-list-split .ant-list-item{
        border-bottom: none;
        &:last-child{
            border-bottom: none !important;
        }
    }
}

// 招股文件
.m-file{
    padding: 40px 0;
    .m-home-h3-title{
        margin-bottom: 20px;
    }
    .m-overflow{
        width: 100%;
        
        overflow: hidden;
    }
    .file-list{
        display: flex;
        padding: 0 60px;
        overflow-x: scroll;
        .file-item{
            flex: 1 0 100%;
            margin: 0 10px;
            padding: 20px 20px 140px 20px;
            background: #F8F9FA url(https://statres.wk1762.com/imgs/file-bg.png) center bottom no-repeat;
            background-size: cover;
            border-radius: 8px;
            span{
                color: #999999;
                font-size: 14px;
            }
            p{
                color: #333333;
                font-size: 16px;
                margin-bottom: 40px;
            }
            a{
                color: #0048FF;
                font-size: 14px;
            }
        }
    }
}

.m-finance{
    background: #f8f9fa; 
    padding: 40px 0;
    .m-home-h3-title{
        margin-bottom: 20px;
    }
    .m-finance-list{
        display: flex;
        flex-wrap: wrap;
        .m-finance-item{
            flex: 0 1 50%;
            text-align: center;
            padding: 0 10px;
            img{
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
            p{
                color: #333333;
                font-size: 14px;
                text-align: center;
                padding-top: 10px;
            }
        }
    }
}